import axios from 'axios';
import queryString from 'query-string';
import { History, Layout, PublicURL } from 'Urls';
import { isArray } from 'lodash-es';
import { getBackedApiUrl } from './url-helper';
import { getCookie, hasSession } from 'features/Auth/hook/sessionHelpers';
import { AuthenticationService } from './AuthenticationService';
import logger from 'loglevel';

const CONCNTRIC_BYPASS_AUTH_COOKIE_NAME = import.meta.env
  .VITE_CONCNTRIC_BYPASS_AUTH_COOKIE_NAME;

const headers = {
  'Content-Type': 'application/json',
} as Record<string, string>;

if (CONCNTRIC_BYPASS_AUTH_COOKIE_NAME) {
  const bypassValue = getCookie(CONCNTRIC_BYPASS_AUTH_COOKIE_NAME) || '';
  if (bypassValue) {
    logger.warn('Bypassing authentication for API requests', bypassValue);
    headers['X-ConCntric-Auth-ByPass'] = bypassValue;
  }
}

export const ApiService = axios.create({
  baseURL: getBackedApiUrl(),
  timeout: import.meta.env.VITE_BACKEND_TIMEOUT || 10000,
  headers,
  withCredentials: true,
});

// Build the error message if the API returns something like:
// {"quantity":["This field is required."]} on response.data
const buildFieldsErrorMessage = (fieldErrors?: Record<string, string[]>) => {
  if (!fieldErrors || typeof fieldErrors !== 'object') {
    return null;
  }

  return Object.keys(fieldErrors).reduce((acc, field) => {
    const error = fieldErrors[field];
    return `${acc} ${field}: ${isArray(error) ? fieldErrors[field].join('. ') : error}`;
  }, '');
};

const authService = AuthenticationService.getInstance();
ApiService.interceptors.request.use(authService.interceptAxiosRequest);

ApiService.interceptors.response.use(
  function (response) {
    return response;
  },
  async function (error) {
    if (
      (error.response ? error.response.status : '') === 401 ||
      error === 'Not authenticated'
    ) {
      const path = History.location?.pathname || '';
      const query = queryString.parse(History.location?.search || '');
      if (
        (!path.startsWith(Layout.PUBLIC) && query.redirect === undefined) ||
        !hasSession()
      ) {
        logger.debug('Request was Unauthorized, logging out');
        History.push(PublicURL.LOGOUT);
      }
    } else if (
      error.response?.status === 400 &&
      error.response?.data?.detail === 'User do not exist in the DB'
    ) {
      authService.keycloakInstance?.logout();
      History.push(PublicURL.AUTH_ERROR);
    }
    const message =
      error.response?.data?.message ||
      error.response?.data?.detail ||
      buildFieldsErrorMessage(error.response?.data) ||
      error.message;
    return Promise.reject(new Error(message, { cause: error }));
  },
);
