export const Resources = {
  // Authentication Resources.
  AUTH: 'api/token/',
  USER_SIGNUP: 'api/users/signup/',
  USER_RESET: 'api/users/reset/',
  REFRESH_AUTH: 'api/token/refresh/',
  CURRENT_USER: 'api/current_user/',
  CURRENT_USER_COMPANIES: 'api/current_user/companies/',
  EXIST_USER: 'api/users/exists/',

  // Permission Roles
  PERMISSION_ROLES: '/api/roles_permissions/',
  PERMISSION_ROLE: '/api/roles/<int:pk>/',
  PERMISSION_ROLE_PERMISSIONS: '/api/roles/<int:pk>/permissions/',
  PERMISSION_ROLE_CLONE: '/api/roles/<int:pk>/clone/',

  // Access Scopes
  ACCESS_SCOPES: '/api/access_scopes/',
  ACCESS_SCOPE: '/api/access_scopes/<int:pk>/',

  // Settings
  SETTINGS: '/api/settings/<str:key>',
  FEATURE_FLAGS: '/api/switches/',

  // Categories Resources.
  ALL_CATEGORIES: 'api/categories/',
  FAVORITE_CATEGORIES: 'api/categories/favorites/',
  FAVORITE_CATEGORY: 'api/categories/<int:pk>/favorite/',
  RECENT_CATEGORIES: 'api/categories/recent/',

  // User & Company Resources.
  COMPANY_BY_ID: 'api/companies/<int:pk>/',
  COMPANY_USERS: 'api/company/users/',
  COMPANY_USER: 'api/company/users/<int:user_id>/',
  COMPANY_USER_RESEND_INVITE: 'api/company/users/<int:user_pk>/resend-invite/',
  COMPANY_USER_ASSIGN_PROJECTS: 'api/company/users/<int:user_pk>/assign-projects/',

  // Projects Resources.
  ALL_PROJECTS: 'api/v2/projects/',
  ALL_PROJECTS_LEAN: 'api/v2/projects_list/',
  PROJECT_BY_ID: 'api/v2/projects/<int:pk>/',
  PROJECT_TEAM_CONTACT: 'api/projects/<int:pk>/team/',
  FORESITE_SUMMARY_BY_ID: 'api/projects/<int:pk>/foresite-summary/',
  PROJECT_COMPARISON_DETAIL_BY_ID: 'api/calibrate/detail/<int:project_id>/',
  PROJECT_COMPARISON_BY_ID: 'api/calibrate/<int:project_id>/',
  PROJECT_COMPARISON_LINE_ITEMS_ID: 'api/calibrate/<int:project_id>/line-items/',
  CALIBRATE_COMPONENTS_SUMMARY: 'api/calibrate/<int:project_id>/components-summary/',
  PROJECTS_AVAILABLE_TO_CALIBRATE: 'api/calibrate/available-projects/',
  PROJECT_UPDATE_STATUS: '/api/v2/projects/<int:project_id>/update-status/',
  PROJECT_DETAIL_FIELD: '/api/v2/third-party/',
  PROJECT_GROUPS: 'api/project-groups/',
  PROJECT_GROUP_BY_ID: 'api/project-groups/<int:pk>/',

  NORMALIZATION_LOCATION: '/api/normalization/location/<int:city_id>/',
  NORMALIZATION_TIMES: '/api/normalization/times/<int:city_id>/',
  NORMALIZATION_CUSTOM:
    '/api/calibrate/<str:entity_name>/<int:entity_id>/custom-normalization/',
  SAVE_NORMALIZATION_LOCATION: '/api/normalization/location/<int:city_id>/',
  SAVE_CUSTOM_NORMALIZATION:
    '/api/calibrate/<int:project_pk>/custom-normalization/<int:normalization_pk>/',
  SAVE_NORMALIZATION_TIMES: '/api/normalization/times/',
  RESET_NORMALIZATION_TIMES: '/api/normalization/<int:project_id>/reset/',

  NOTIFICATIONS: '/api/notifications/',
  NOTIFICATIONS_READ: '/api/notifications/read/',

  // Target Value Design
  TVD_FORESITE_BY_ID: 'api/tvd/foresite/<int:pk>/',
  TVD_PROGRAM_BY_ID: 'api/tvd/program/<int:pk>/',
  TVD_CALIBRATE_BY_ID: 'api/tvd/calibrate/<int:pk>/',
  TVD_DIMENSION_MEMBER_PARAMS:
    'api/tvd/<int:project_pk>/dimension-member-params/<str:dimension_type>/<int:dimension_member_id>/',
  TVD_DIMENSION_MEMBERS_PARAMS:
    'api/tvd/<int:project_pk>/dimension-member-params/<str:dimension_type>/',
  TVD_DIMENSIONS_USAGE: 'api/tvd/<int:project_pk>/dimensions-data-status/',
  TVD_COST_GROUP_SYSTEMS: 'api/tvd/cost-group-systems/',

  // Legacy projects
  PROJECT_LEGACY: 'api/v2/projects/legacy/',
  PROJECT_LEGACY_BY_ID: 'api/v2/projects/legacy/<int:pk>/',

  // Project members Resources.
  PROJECT_TEAM_INVITE: 'api/projects/<int:project_pk>/team/invite/',
  PROJECT_MEMBER_BY_ID: 'api/projects/<int:project_pk>/team/<int:user_pk>/',
  PROJECT_MEMBERS_BY_MODULE:
    '/api/v2/projects/<int:project_pk>/<str:module_name>/members/',

  // Scenarios Resources.
  ALL_SCENARIOS_BY_PROJECT_ID: 'api/projects/<int:project_pk>/scenarios/',
  SCENARIO_BY_ID: 'api/projects/<int:project_pk>/scenarios/<int:pk>/',
  APPROVE_SCENARIO_BY_ID: 'api/projects/<int:project_pk>/scenarios/<int:pk>/approve/',
  REJECT_SCENARIO_BY_ID: 'api/projects/<int:project_pk>/scenarios/<int:pk>/reject/',

  ENTITY_ATTACHMENTS: 'api/attachments/<str:entity_name>/<int:entity_id>/',
  ATTACHMENT_BY_ID: 'api/attachments/<int:pk>/',
  // Scenario Items Resources.
  ALL_ITEMS_BY_PROJECT_ID: 'api/projects/<int:project_pk>/scenario-items/',
  ITEM_BY_ID: 'api/projects/<int:project_pk>/scenario-items/<int:pk>/',
  APPROVE_ITEM_BY_ID: 'api/projects/<int:project_pk>/scenario-items/<int:pk>/approve/',
  REJECT_ITEM_BY_ID: 'api/projects/<int:project_pk>/scenario-items/<int:pk>/reject/',
  REVERT_ITEM_BY_ID: 'api/projects/<int:project_pk>/scenario-items/<int:pk>/revert/',
  BATCH_ASSIGN_BY_SCENARIO_ID:
    'api/projects/<int:project_pk>/scenario-items-batch-assign/<int:scenario_pk>/',
  ASSIGN_ITEM_TO_SCENARIO_BY_ID:
    'api/projects/<int:project_pk>/scenario-item-assign/<int:scenario_pk>/<int:pk>/',
  UNASSIGN_ITEM_FROM_SCENARIO_BY_ID:
    'api/projects/<int:project_pk>/scenario-item-unassign/<int:scenario_pk>/<int:pk>/',
  ITEM_CLONE: '/api/projects/<int:project_pk>/scenario-items/<int:pk>/clone/',
  MUTUALLY_EXCLUSIVE_LIST:
    'api/projects/<int:project_pk>/scenario-items/<int:pk>/mutually-exclusive/',
  IMPORT_SCENARIO_ITEMS: 'api/projects/<int:project_pk>/scenario-items/import/',
  SAVE_ITEM_BATCH: 'api/projects/<int:project_pk>/scenario-items/bulk-create/',

  // Entity Comments
  ENTITY_COMMENTS: 'api/comments/<str:entity_name>/<int:entity_id>/',
  COMMENTS: 'api/comments/<int:pk>/',

  // Entity Links
  ENTITY_LINKS: 'api/links/<str:entity_name>/<int:entity_id>/',
  LINKS: 'api/links/<int:pk>/',

  // Scenario Sub Items Resources.
  ALL_SUBITEMS_BY_ITEM_ID:
    'api/projects/<int:project_pk>/scenario-items/<int:scenario_item_pk>/',

  // Design Milestone Resources.
  ALL_MILESTONES_BY_PROJECT_ID: 'api/projects/<int:project_pk>/design-milestones/',
  MILESTONE_BY_ID: 'api/projects/<int:project_pk>/design-milestones/<int:pk>/',
  DESIGN_MILESTONES_LINE_ITEMS: 'api/projects/<int:project_pk>/milestone/<int:pk>/',

  RISK_BY_ID: 'api/projects/<int:project_id>/risks/<int:risk_id>/',
  PROJECT_RISKS: 'api/projects/<int:project_id>/risks/',
  RISK_MITIGATION_BY_ID: 'api/risks/<int:risk_id>/mitigations/<int:mitigation_id>/',
  RISK_CATEGORIES: 'api/risks/categories',
  OWNER_COSTS:
    'api/projects/<int:project_pk>/design-milestones/<int:milestone_id>/owner-costs/',
  OWNER_COST_BY_ID:
    'api/projects/<int:project_pk>/design-milestones/<int:milestone_id>/owner-costs/<int:cost_id>/',

  // Units Resources.
  ALL_UNITS: 'api/units/',
  ALL_BASE_UNITS: 'api/base-units/',

  // Components Resources.
  ALL_COMPONENTS_BY_PROJECT_ID: 'api/projects/<int:pk>/components/',
  COMPONENT_BY_ID: 'api/projects/<int:project_pk>/components/<int:pk>/',
  COMPONENT_BASE_UNITS:
    'api/v2/projects/<int:project_pk>/components/<int:pk>/base-units/',
  COMPONENT_BASE_UNIT:
    'api/v2/projects/<int:project_pk>/components/<int:pk>/base-units/<base_unit:id>/',

  // Cost Group Resources
  ALL_COST_GROUPS: 'api/cost-groups/',
  COST_GROUP_CATEGORIES: 'api/cost-group-categories/',
  SUBTOTALS_BY_MILESTONE_ID:
    'api/projects/<int:project_pk>/design-milestones/<int:pk>/sub-totals/',

  // CPE-4046
  MILESTONE_ESTIMATE_UPLOADS: 'api/projects/<int:project_pk>/milestone-estimate-uploads/',
  MILESTONE_ESTIMATE_UPLOADS_BY_ID:
    'api/projects/<int:project_pk>/milestone-estimate-uploads/<int:pk>/',
  MILESTONE_ESTIMATE_DOWNLOAD:
    'api/projects/<int:project_pk>/milestone-estimate-uploads/download/<int:pk>/',
  MILESTONE_ESTIMATE_UPLOAD_AI_ASSISTANT:
    'api/projects/<int:project_pk>/milestone-estimate-uploads/<int:id>/ai-assistant/',
  GENERIC_MAPPERS: 'api/generic-mappers/',
  GENERIC_MAPPERS_BY_ID: 'api/generic-mappers/<int:pk>/',

  // SUBTOTALS_UPLOAD_SAGE: 'api/subtotals-upload/sage/<int:pk>/',
  ESTIMATE_CUSTOM_TEMPLATES: 'api/company/estimate-templates/',
  ESTIMATE_CUSTOM_TEMPLATE: 'api/company/estimate-template/<int:template_pk>/',
  ESTIMATE_CLEAR:
    '/api/projects/<int:project_pk>/design-milestones/<int:design_milestone_pk>/design-milestone-costs/',
  COPY_MARKUPS: 'api/markups/copy/',
  COPY_OWNER_ITEMS: '/api/design-milestones/<milestone>/owner-costs/copy/',

  // Overview
  PROJECT_TOTALS_SUMMARY: 'api/projects/<int:project_pk>/totals-summary/',
  PROJECT_MISSING_DATA_INFO: 'api/projects/<int:project_pk>/missing-data-info/',
  PROJECT_COST_BREAKDOWN: 'api/projects/<int:project_pk>/cost-breakdown-info/',

  // Markups
  ESTIMATE_MARKUPS:
    'api/projects/<int:project_pk>/design-milestones/<int:milestone_pk>/markups/',
  ITEM_MARKUPS: 'api/projects/<int:project_pk>/scenario-items/<int:item_pk>/markups/',
  MARKUP_BY_ID:
    'api/projects/<int:project_pk>/<str:entity>/<int:entity_id>/markups/<int:pk>/',

  // Markup Templates
  MARKUP_TEMPLATES_GROUPS: 'api/markup-templates-groups/',
  MARKUP_TEMPLATES_GROUP: 'api/markup-templates-groups/<int:pk>',
  IMPLEMENT_MARKUP_TEMPLATES_GROUP: 'api/markup-templates-groups/<int:pk>/implement',

  // PDF FILE DOWNLOAD
  FORESITE_PDF_DOWNLOAD: 'api/v2/projects/<int:pk>/foresite/export',
  ESTIMATE_REPORT_DOWNLOAD:
    'api/projects/<int:project_pk>/design-milestones/<int:pk>/export/',
  FULL_ESTIMATE_REPORT_DOWNLOAD:
    'api/projects/<int:project_pk>/design-milestones/<int:pk>/export-estimate/',
  ITEM_REPORT_DOWNLOAD:
    '/reports/projects/<int:project_pk>/scenario-items/<int:item_pk>/',
  PROJECTS_REPORT_DOWNLOAD: '/reports/projects',
  ITEM_REPORTS_INFO: '/reports/projects/<int:project_pk>/scenario-items/info/',
  TVD_REPORTS_INFO: '/reports/projects/<int:project_pk>/tvd/',
  BASE_REPORT_INFO: '/reports/projects/<int:project_pk>/base/',
  PORTFOLIO_REPORTS_INFO: '/reports/portfolio/',
  RISKS_PDF_DOWNLOAD: '/reports/projects/<int:project_pk>/risks',
  ISSUES_PDF_DOWNLOAD: '/reports/projects/<int:project_pk>/issues',
  CALIBRATE_BY_DIVISION_TABLE_REPORT:
    '/reports/projects/<int:project_pk>/calibrate/division/',

  // Delivery Contract Types
  DELIVERY_CONTRACT_TYPES: 'api/project_delivery_contract_types/',

  // Dashboards
  PROJECT_DASHBOARDS: 'api/dashboards/projects-dashboards/',
  PROJECT_DASHBOARD_MEMBERSHIPS:
    'api/dashboards/projects-dashboards/<int:dashboard_pk>/memberships/',
  PROJECT_DASHBOARD_BY_ID: 'api/dashboards/projects-dashboards/<int:pk>/',
  PROJECT_DASHBOARD_METRICS:
    'api/dashboards/projects-dashboards/<int:dashboard_pk>/metric-settings/',
  PROJECT_DASHBOARD_METRICS_REORDER:
    'api/dashboards/projects-dashboards/<int:dashboard_pk>/metric-settings/reorder/',
  PROJECT_DASHBOARD_METRICS_BY_ID:
    'api/dashboards/projects-dashboards/<int:dashboard_pk>/metric-settings/<int:pk>/',
  PROJECT_DASHBOARD_IMAGE: 'api/dashboards/projects-dashboards/<int:dashboard_pk>/image/',
  DASHBOARD_METRICS: 'api/dashboards/metrics/',
  DASHBOARD_PERMISSIONS: 'api/dashboards/permissions/',
  PROJECT_DASHBOARD_ALL_PROJECTS_REPORT:
    '/reports/projects-dashboards/<int:dashboard_pk>/projects/',
  DASHBOARD_PROJECT_DASHBOARD_SETTING_DATA:
    'api/dashboards/projects-dashboards/<int:dashboard_pk>/metric-settings/<int:pk>/data',
  DASHBOARD_PROJECT_DASHBOARD_SETTING_PREVIEW:
    'api/dashboards/projects-dashboards/<int:dashboard_pk>/metric-settings/preview/',
  DASHBOARD_PROJECT_DASHBOARD_CUSTOM_METRIC_FEATURE_DEFINITION:
    'api/dashboards/projects-dashboards/custom-metric-settings/<str:feature>/definitions',
  DASHBOARD_PORTFOLIO_METRIC: 'api/dashboards/portfolio/<str:metric_slug>/',
  DASHBOARD_PROJECT_DASHBOARD_AI_ASSISTANT:
    'api/dashboards/projects-dashboards/<int:dashboard_pk>/metric-settings/ai-assistant/',
  DASHBOARD_PROJECT_DASHBOARD_AI_ASSISTANT_DETAILS:
    'api/dashboards/projects-dashboards/<int:dashboard_pk>/metric-settings/ai-assistant/<int:assistant_id>/',

  // Concept Lab
  CONCEPT_ANALYSIS_LIST: 'api/concept-lab/analysis/',
  CONCEPT_ANALYSIS_BY_ID: 'api/concept-lab/analysis/<int:analysis_pk>/',
  CLEAR_CONCEPT_ANALYSIS_BY_ID: 'api/concept-lab/analysis/<int:analysis_pk>/clear/',
  CONCEPT_VARIANTS_LIST: 'api/concept-lab/variants/',
  CONCEPT_VARIANTS_BY_ID: 'api/concept-lab/variants/<int:variant_pk>/',
  CONCEPT_VARIANT_SUMMARY_BY_ID: 'api/concept-lab/variant-summary/<int:variant_pk>/',

  // Concept Hub Scenarios
  CONCEPT_SCENARIOS: '/api/concept-lab/analysis/<int:analysis_pk>/concepts/',
  CONCEPT_SCENARIO_BY_ID:
    '/api/concept-lab/analysis/<int:analysis_pk>/concepts/<int:scenario_pk>/',

  // Concept Hub Markups
  CONCEPT_SCENARIOS_MARKUPS:
    '/api/concept-lab/analysis/<int:analysis_pk>/concepts/<int:scenario_pk>/markups/',
  CONCEPT_SCENARIOS_MARKUPS_BY_ID:
    '/api/concept-lab/analysis/<int:analysis_pk>/concepts/<int:scenario_pk>/markups/<int:markup_pk>/',

  // Concept Owner Costs
  CONCEPT_SCENARIOS_OWNER_COSTS:
    '/api/concept-lab/analysis/<int:analysis_pk>/concepts/<int:scenario_pk>/owner-costs/',
  CONCEPT_SCENARIOS_OWNER_COST_BY_ID:
    '/api/concept-lab/analysis/<int:analysis_pk>/concepts/<int:scenario_pk>/owner-costs/<int:owner_cost_pk>/',

  ANALYSIS_CONCEPTS_BUDGETS_SUMMARY:
    'api/concept-lab/analysis/<int:analysis_pk>/compare/ ',
  ANALYSIS_CONCEPTS_SUMMARY:
    'api/concept-lab/analysis/<int:analysis_pk>/compare-summary/',

  CONCEPT_LAB_LINE_ITEMS: 'api/concept-lab/playground-line-items/',
  CONCEPT_LAB_LINE_ITEM_BY_ID:
    'api/concept-lab/playground-line-items/<int:line_item_pk>/',

  CONCEPT_LAB_ANALYSIS_PROMOTE: 'api/concept-lab/analysis/<int:analysis_pk>/promote/',

  // Library
  LIBRARY_CREATE_IDEA: 'api/ideas/clone/',
  LIBRARY_IDEAS: 'api/ideas/',
  LIBRARY_IDEA_BY_ID: 'api/ideas/<int:idea_pk>/',
  LIBRARY_INSTANTIATE_IDEA: 'api/ideas/<int:idea_pk>/instantiate/',
  LIBRARY_IDEA_IMAGES: '/api/ideas/<int:idea_pk>/images/',

  // Area setup
  COMPONENT_AREAS: 'api/v2/projects/<int:project_pk>/component-areas/',
  COMPONENT_AREAS_BY_ID:
    'api/v2/projects/<int:project_pk>/component-areas/<int:area_pk>/',
  COMPONENTS_USAGE: 'api/v2/projects/<int:project_pk>/components-usage/',
  COMPONENT_USAGE_UPDATE:
    'api/v2/projects/<int:project_pk>/components-usage/<int:component_pk>/',

  // Custom Formats
  CUSTOM_CODE_UPLOAD_TEMPLATE: 'api/custom-cost-group/definitions/upload/',
  CUSTOM_CODE_DEFINITIONS: 'api/custom-cost-group/definitions/',
  CUSTOM_CODE_DEFINITION_BY_ID: 'api/custom-cost-group/definitions/<int:ccd_pk>/',

  // Procurement
  PROCUREMENT_BID_COST_GROUPS:
    'api/projects/<int:project_id>/procurement/bid/cost-group/',
  PROCUREMENT_BID_PACKAGES: 'api/projects/<int:project_id>/procurement/bid/packages/',
  PROCUREMENT_BID_PACKAGES_BY_ID:
    'api/projects/<int:project_id>/procurement/bid/packages/<int:bid_package_id>/',
  PROCUREMENT_BID_PACKAGES_BULK:
    'api/projects/<int:project_id>/procurement/bid/packages/bulk/',
  PROCUREMENT_DOCUMENT_SPECIFICATIONS:
    'api/projects/<int:project_id>/procurement/documents/specs/',
  PROCUREMENT_BID_PACKAGE_PRIORITIES:
    'api/projects/<int:project_id>/procurement/bid-package-priorities/',
  PROCUREMENT_BID_PACKAGE_PRIORITY_BY_ID:
    'api/projects/<int:project_id>/procurement/bid-package-priorities/<int:pk>/',
  PROCUREMENT_SPECIFICATIONS: 'api/projects/<int:project_id>/procurement/specs/',
  PROCUREMENT_BOARD_STATUSES: 'api/projects/<int:project_id>/procurement/board/statuses/',
  PROCUREMENT_BOARD_STATUS_BY_ID:
    'api/projects/<int:project_id>/procurement/board/statuses/<int:board_status_id>/',
  PROCUREMENT_BID_PACKAGE_PULL_PLAN_EVENT_BY_ID:
    'api/projects/<int:project_id>/procurement/bid-package-pull-plan-events/<int:pk>/',
  PROCUREMENT_BID_PACKAGE_PULL_PLAN_EVENT_BULK_UPDATE:
    'api/projects/<int:project_id>/procurement/bid-package-pull-plan-events/bulk/',

  TAGS: 'api/v2/projects/<int:project_pk>/tags/',
  BUILDING_CONNECTED_LOGIN: 'api/buildingconnected/login/',
  BUILDING_CONNECTED_PROJECTS: 'api/buildingconnected/projects/',
  BUILDING_CONNECTED_LINKED_PROJECTS: 'api/buildingconnected/linkedprojects/',
  BUILDING_CONNECTED_BID_PACKAGES: 'api/buildingconnected/bidpackages/',
  BUILDING_CONNECTED_LINKED_BID_PACKAGES: 'api/buildingconnected/linkedbidpackages/',
  BUILDING_CONNECTED_ACC_PROJECTS: 'api/buildingconnected/acc-projects/',

  // Public API
  API_KEYS: 'api/public_api/',
  API_KEYS_PERMISSIONS_ALL: 'api/public_api/permissions/all/',
  API_KEY: 'api/public_api/<str:prefix>/',
  API_SCHEMA: 'public/api/schema',

  TIMELINE_REORDER: 'api/projects/<int:project_pk>/timeline/reorder/',
  TIMELINE_PHASES: 'api/projects/<int:project_pk>/timeline-phases/',
  TIMELINE_PHASE_BY_ID: 'api/timeline-phase/<int:pk>/',
  TIMELINE_ACTIVITIES: 'api/projects/<int:project_pk>/timeline-activities/',
  TIMELINE_ACTIVITY_BY_ID: 'api/timeline-activity/<int:pk>/',
  TIMELINE_ACTIVITY_GROUPS: 'api/projects/<int:project_pk>/timeline-activities-groups/',
  TIMELINE_ACTIVITY_GROUP_BY_ID: 'api/timeline-activity-group/<int:pk>/',
  TIMELINE_ACTIVITY_GROUP_CLONE: 'api/timeline-activity-group/<int:pk>/clone/',
  TIMELINE_CONTRACT_EVENTS: 'api/projects/<int:project_pk>/timeline-contract-events/',
  TIMELINE_CONTRACT_EVENT: 'api/timeline-contract-event/<int:pk>/',
  TIMELINE_EXPORT: 'api/projects/<int:pk>/timeline/export',

  // Context
  CONTEXT_WIDGETS: 'api/projects/<int:project_id>/context/widgets/',
  CONTEXT_WIDGET: 'api/projects/<int:project_id>/context/widgets/<int:widget_id>/',
  AUTODESK_VIEWER_DESIGN_FILES: '/api/buildingconnected/<int:project_id>/design-files/',
  AUTODESK_2LEGGED_TOKEN: '/api/buildingconnected/2legged-token/',

  // issues
  ISSUES: 'api/projects/<int:project_pk>/issues/',
  ISSUE_BY_ID: 'api/projects/<int:project_pk>/issues/<int:pk>/',
  UPDATE_ISSUE_RELATED_TO: 'api/issues/<int:pk>/map/',
  ISSUE_TYPES: 'api/issues/type/',
  ISSUE_SUBTYPES: 'api/issues/subtype/',
  ISSUE_OPTION: 'api/issues/options/',
  ISSUE_INSIGHTS: 'api/projects/<int:project_pk>/issues/insights/',
  RELATED_ISSUES:
    'api/projects/<int:project_pk>/issues/related_with/<str:entity_type>/<int:entity_id>/',

  // History
  FORESITE_ITEM_HISTORY: 'api/projects/<int:project_id>/scenario-items/<int:pk>/history/',
  RISK_ITEM_HISTORY: 'api/projects/<int:project_id>/risks/<int:risk_id>/history/',
  ISSUE_ITEM_HISTORY: 'api/projects/<int:project_id>/issues/<int:issue_id>/history/',

  // Saved filters
  LIST_SAVED_FILTERS: 'api/saved-filters/<str:entity_name>/',
  SAVED_FILTER_BY_ID: 'api/saved-filter/<int:pk>/',

  // Custom Attributes
  CUSTOM_ATTRIBUTE_GROUPS: 'api/custom-attributes/groups/',
  CUSTOM_ATTRIBUTE_GROUP: 'api/custom-attributes/groups/<cag_int:pk>/',
  CUSTOM_ATTRIBUTES: 'api/custom-attributes/groups/<cag_int:pk>/attributes/',
  CUSTOM_ATTRIBUTE: 'api/custom-attributes/groups/<cag_int:pk>/attributes/<int:pk>/',
  ENTITY_ATTRIBUTES_CONFIGURATION:
    'api/custom-attributes/attribute-configurations/<str:model_name>/<int:entity_pk>/',
  ENTITY_CUSTOM_ATTRIBUTE_VALUES:
    'api/custom-attributes/attributes-values/<str:model_name>/<int:entity_pk>/',
  CALIBRATE_ENTITY_CUSTOM_ATTRIBUTE_VALUES:
    'api/custom-attributes/attribute-values/<str:model_name>/',
  CUSTOM_ATTRIBUTE_RETRIEVE: 'api/custom-attributes/attribute/<int:entity_pk>/',

  // Calibrate
  SELECT_PROJECTS: 'api/settings/calibrate?project_id=<int:project_id>/',
  CALIBRATE_PAGE: 'api/calibrate/<int:project_id>/',
} as const;
