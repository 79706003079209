import createPalette, { PaletteOptions } from '@mui/material/styles/createPalette';
import { MitigationStatus, RiskScoreLevel, RiskStatus } from 'types/Risk';
import { TargetBudgetStatus } from 'types/TargetValueDesign';
import { ProjectStatus } from 'types/Project';
import { IssueStatus } from 'types/Issue';
import { CONCEPT_PROJECT_STATUS } from 'features/ConceptLab/type';
import { ItemPriority, ItemStatus } from 'types/Item';

interface ICustomColor {
  main: string;
  50: string;
  75?: string;
  100: string;
  200: string;
  300: string;
  400: string;
  500: string;
  600: string;
  700: string;
  800: string;
  900: string;
}

const commonColors = {
  black: '#000000',
  white: '#FFFFFF',
};

const greyColor: Omit<ICustomColor, 900> = {
  main: '', // 700
  50: '#F7F8FB',
  75: '#F0F2F6',
  100: '#DADFEA',
  200: '#CFD2DF',
  300: '#AFB3C3',
  400: '#8F9AB1',
  500: '#576073',
  600: '#495164',
  700: '#2F3340',
  800: '#0C0E13',
};
greyColor.main = greyColor[700];

const blueColor: ICustomColor = {
  main: '',
  50: '#EBF2FF',
  100: '#D3E2FF',
  200: '#B4CDFF',
  300: '#98BBFF',
  400: '#6D9DF9',
  500: '#5284E4',
  600: '#4476D4',
  700: '#366ACC',
  800: '#315DB1',
  900: '#243D93',
};
blueColor.main = blueColor[700];

const pumpkinColor: Omit<ICustomColor, 'main'> = {
  50: '#FFEBE2',
  100: '#FDD9C8',
  200: '#FEC1A6',
  300: '#F5AA89',
  400: '#F29A79',
  500: '#E97F57',
  600: '#E3734A',
  700: '#D45E32',
  800: '#B2502D',
  900: '#692F16',
};

const successGreenColor: Omit<ICustomColor, 'main'> = {
  50: '#E3F9EC',
  100: '#CCEDDA',
  200: '#A9DDBF',
  300: '#94CFAD',
  400: '#74B191',
  500: '#559D77',
  600: '#478D68',
  700: '#348059',
  800: '#2E714F',
  900: '#214F37',
};

const peackockColor: Omit<ICustomColor, 'main'> = {
  50: '#E6F8F9',
  100: '#C5ECEF',
  200: '#9EDFE5',
  300: '#75D2DA',
  400: '#4BB5C3',
  500: '#28A3B4',
  600: '#1595A6',
  700: '#138494',
  800: '#107482',
  900: '#0B525B',
};

const categoricYellowColor: Omit<ICustomColor, 'main'> = {
  50: '#FFF3D0',
  100: '#FFEEB8',
  200: '#FFE9A7',
  300: '#F9DB90',
  400: '#F3D381',
  500: '#F0C85F',
  600: '#E7BB4D',
  700: '#CF9849',
  800: '#9D6232',
  900: '#68311D',
};

const orangeColor: Omit<ICustomColor, 'main'> = {
  50: '#FFEDD8',
  100: '#FFDCB1',
  200: '#FFC075',
  300: '#F5AA4F',
  400: '#F19A32',
  500: '#E18922',
  600: '#D57F1B',
  700: '#C16800',
  800: '#894A00',
  900: '#512C00',
};

const categoricPurpleColor: ICustomColor = {
  main: '',
  50: '#F2EAFF',
  100: '#EAE1F8',
  200: '#D4BBF3',
  300: '#C8A6F2',
  400: '#AD83E1',
  500: '#9767D6',
  600: '#8658C0',
  700: '#7F48C7',
  800: '#723BB9',
  900: '#591EA5',
};
categoricPurpleColor.main = categoricPurpleColor[700];

const categoricPinkColor: Omit<ICustomColor, 'main'> = {
  50: '#FFE7F7',
  100: '#FFD1F1',
  200: '#F4B6E1',
  300: '#F393D6',
  400: '#E270BF',
  500: '#CF52A8',
  600: '#CF3CA2',
  700: '#BE3092',
  800: '#B02485',
  900: '#930D6A',
};

const cautionRedColor: Omit<ICustomColor, 'main'> = {
  50: '#FFEBED',
  100: '#FFD3D7',
  200: '#FAB8BB',
  300: '#F69CA2',
  400: '#F8747D',
  500: '#DA5A63',
  600: '#DB4755',
  700: '#D03544',
  800: '#BD2E3B',
  900: '#8E222D',
};

const categoricAppleColor: Omit<ICustomColor, 'main'> = {
  50: '#D1EFC3',
  100: '#D1EFC3',
  200: '#95D479',
  300: '#95D479',
  400: '#43AB48',
  500: '#43AB48',
  600: '#339E38',
  700: '#2C8C31',
  800: '#227D27',
  900: '#165C19',
};

const fucsiaColor: Omit<ICustomColor, 'main'> = {
  50: '#FFECF0',
  100: '#FFD1DC',
  200: '#FFB4C6',
  300: '#F998AF',
  400: '#F089A2',
  500: '#E5708C',
  600: '#E1496D',
  700: '#C63556',
  800: '#A9344F',
  900: '#7D2136',
};

const categoricGrapeColor: Omit<ICustomColor, 'main'> = {
  50: '#FFE8FC',
  100: '#F2D6EF',
  200: '#E2BDDD',
  300: '#D8A3D1',
  400: '#BC7EB4',
  500: '#AA63A1',
  600: '#96578E',
  700: '#894B81',
  800: '#764570',
  900: '#572E52',
};

const scenarioColors = [
  {
    color: categoricPurpleColor[900],
    backgroundColor: categoricPurpleColor[100],
  },
  {
    color: blueColor[800],
    backgroundColor: blueColor[100],
  },
  {
    color: peackockColor[800],
    backgroundColor: peackockColor[100],
  },
  {
    color: categoricPinkColor[800],
    backgroundColor: categoricPinkColor[100],
  },
  {
    color: orangeColor[800],
    backgroundColor: orangeColor[100],
  },
  {
    color: categoricAppleColor[800],
    backgroundColor: categoricAppleColor[100],
  },
  {
    color: categoricGrapeColor[800],
    backgroundColor: categoricGrapeColor[100],
  },
  {
    color: fucsiaColor[800],
    backgroundColor: fucsiaColor[100],
  },
  {
    color: greyColor[800],
    backgroundColor: greyColor[100],
  },
  {
    color: cautionRedColor[800],
    backgroundColor: cautionRedColor[100],
  },
];

const projectStatusesColors: Record<
  ProjectStatus | CONCEPT_PROJECT_STATUS,
  { color: string; background: string }
> = {
  ACTIVE: {
    color: blueColor[700],
    background: blueColor[50],
  },
  DRAFT: {
    color: greyColor[600],
    background: greyColor[100],
  },
  DRAFT_LEGACY: {
    color: greyColor[600],
    background: greyColor[100],
  },
  COMPLETED: {
    color: successGreenColor[700],
    background: successGreenColor[100],
  },
  ABANDONED: {
    color: cautionRedColor[700],
    background: cautionRedColor[100],
  },
  LEGACY: {
    color: greyColor[700],
    background: categoricYellowColor[100],
  },
  CONCEPT: {
    color: categoricPurpleColor[700],
    background: categoricPurpleColor[100],
  },
  PLAYGROUND: {
    color: blueColor[700],
    background: blueColor[50],
  },
} as const;

const projectMembersColors = [
  {
    color: categoricPurpleColor[900],
    background: categoricPurpleColor[100],
  },
  {
    color: blueColor[900],
    background: blueColor[100],
  },
  {
    color: peackockColor[900],
    background: peackockColor[100],
  },
  {
    color: categoricPinkColor[900],
    background: categoricPinkColor[100],
  },
  {
    color: orangeColor[900],
    background: orangeColor[100],
  },
  {
    color: categoricAppleColor[900],
    background: categoricAppleColor[100],
  },
  {
    color: categoricGrapeColor[900],
    background: categoricGrapeColor[100],
  },
  {
    color: fucsiaColor[900],
    background: fucsiaColor[100],
  },
  {
    color: cautionRedColor[900],
    background: cautionRedColor[100],
  },
] as const;

const mitigationStatusesColors: Record<
  MitigationStatus,
  { color: string; background: string }
> = {
  IN_PROGRESS: {
    color: blueColor[700],
    background: blueColor[50],
  },
  DRAFT: {
    color: greyColor[600],
    background: greyColor[100],
  },
  COMPLETE: {
    color: successGreenColor[700],
    background: successGreenColor[100],
  },
} as const;

const riskStatusesColors: Record<
  RiskStatus,
  { color: string; background: string; dashboardColor: string }
> = {
  IDENTIFIED: {
    color: categoricYellowColor[900],
    background: categoricYellowColor[200],
    dashboardColor: categoricYellowColor[600],
  },
  CLOSED: {
    color: successGreenColor[700],
    background: successGreenColor[100],
    dashboardColor: successGreenColor[900],
  },
} as const;

export const issueStatusesColors: Record<
  IssueStatus,
  { color: string; background: string; dashboardColor: string }
> = {
  DRAFT: {
    color: greyColor[600],
    background: greyColor[100],
    dashboardColor: greyColor[200],
  },
  OPEN: {
    color: greyColor[600],
    background: greyColor[100],
    dashboardColor: orangeColor[200],
  },
  PENDING: {
    color: blueColor[700],
    background: blueColor[50],
    dashboardColor: blueColor[300],
  },
  DISPUTE: {
    color: categoricYellowColor[900],
    background: categoricYellowColor[200],
    dashboardColor: categoricYellowColor[700],
  },
  REJECTED: {
    color: cautionRedColor[700],
    background: cautionRedColor[100],
    dashboardColor: cautionRedColor[900],
  },
  CLOSED: {
    color: successGreenColor[700],
    background: successGreenColor[100],
    dashboardColor: successGreenColor[900],
  },
  VOID: {
    color: greyColor[600],
    background: greyColor[100],
    dashboardColor: categoricPurpleColor[900],
  },
} as const;

export const prioritiesColors: Record<ItemPriority, { dashboardColor: string }> = {
  HIGHEST: {
    dashboardColor: cautionRedColor[900],
  },
  HIGH: {
    dashboardColor: cautionRedColor[500],
  },
  MEDIUM: {
    dashboardColor: categoricYellowColor[600],
  },
  LOW: {
    dashboardColor: blueColor[400],
  },
  LOWEST: {
    dashboardColor: blueColor[700],
  },
} as const;

export const voiStatusesColors: Record<
  ItemStatus,
  { color: string; background: string; dashboardColor: string }
> = {
  DRAFT: {
    color: greyColor[600],
    background: greyColor[100],
    dashboardColor: greyColor[200],
  },
  VOID: {
    color: greyColor[600],
    background: greyColor[50],
    dashboardColor: categoricPurpleColor[900],
  },
  PENDING: {
    color: categoricYellowColor[900],
    background: categoricYellowColor[100],
    dashboardColor: categoricYellowColor[300],
  },
  REJECTED: {
    color: pumpkinColor[800],
    background: pumpkinColor[100],
    dashboardColor: cautionRedColor[900],
  },
  APPROVED: {
    color: successGreenColor[800],
    background: successGreenColor[100],
    dashboardColor: successGreenColor[900],
  },
} as const;

export const filterGroupColors = [
  greyColor[200],
  greyColor[50],
  greyColor[75],
  greyColor[100],
];
const riskScoreColors: Record<
  RiskScoreLevel,
  { color: string; background: string; levelColor: string; dashboardColor: string }
> = {
  None: {
    color: `${successGreenColor[800]} !important`,
    background: successGreenColor[50],
    levelColor: successGreenColor[600],
    dashboardColor: greyColor[100],
  },
  Low: {
    color: `${successGreenColor[800]} !important`,
    background: successGreenColor[50],
    levelColor: successGreenColor[600],
    dashboardColor: successGreenColor[900],
  },
  Medium: {
    color: `${categoricYellowColor[800]} !important`,
    background: categoricYellowColor[50],
    levelColor: categoricYellowColor[500],
    dashboardColor: categoricYellowColor[600],
  },
  High: {
    color: `${pumpkinColor[800]} !important`,
    background: pumpkinColor[100],
    levelColor: pumpkinColor[700],
    dashboardColor: cautionRedColor[900],
  },
} as const;

// Colors taken from here https://www.figma.com/file/q2O4Pz35oTI2i9enKNrXJU/ConC-UI-Library?node-id=601%3A11399&t=zBY9O6OZIiDC8vNl-0
const tvdColors = [
  successGreenColor[500],
  blueColor[100],
  categoricPinkColor[900],
  pumpkinColor[400],
  greyColor[600],
  categoricPinkColor[100],
  categoricPurpleColor[700],
  pumpkinColor[200],
  categoricAppleColor[800],
  categoricYellowColor[400],
  fucsiaColor[700],
  blueColor[200],
  categoricGrapeColor[700],
  categoricYellowColor[600],
  peackockColor[100],
  pumpkinColor[600],
  blueColor[900],
  categoricYellowColor[100],
] as const;

// CPE-3199
// See https://www.figma.com/file/q2O4Pz35oTI2i9enKNrXJU/ConC-UI-Library?type=design&mode=design&t=KjLsL0pjx5m9wUi9-0
const componentColors = [
  blueColor[300],
  categoricGrapeColor[800],
  categoricYellowColor[200],
  blueColor[900],
  peackockColor[200],
  pumpkinColor[700],
  categoricAppleColor[50],
  categoricPurpleColor[600],
  categoricPinkColor[200],
  peackockColor[800],
  orangeColor[200],
] as const;

const dashboardProjectsColors = [
  blueColor[900],
  orangeColor[200],
  categoricPurpleColor[900],
  peackockColor[300],
  fucsiaColor[800],
  categoricYellowColor[200],
  categoricAppleColor[900],
  pumpkinColor[300],
  blueColor[800],
  categoricYellowColor[600],
  categoricGrapeColor[800],
  blueColor[300],
  orangeColor[800],
  categoricPurpleColor[200],
];

const projectComparisonColors = {
  // Colors taken from https://www.figma.com/file/E7Y2fml4OC9eweDeGDHx8U/Calibrate?node-id=1022%3A54167
  otherProjects: [
    pumpkinColor[200],
    categoricPurpleColor[900],
    peackockColor[300],
    fucsiaColor[800],
    categoricYellowColor[200],
    categoricAppleColor[900],
    pumpkinColor[300],
    blueColor[800],
    categoricYellowColor[600],
    categoricGrapeColor[800],
    blueColor[300],
    pumpkinColor[300],
    categoricPurpleColor[200],
  ],
  otherProjectsBackground: [
    pumpkinColor[50],
    categoricPurpleColor[50],
    peackockColor[50],
    fucsiaColor[50],
    categoricYellowColor[50],
    categoricAppleColor[50],
    pumpkinColor[50],
    blueColor[50],
    categoricYellowColor[50],
    categoricGrapeColor[50],
    blueColor[50],
    pumpkinColor[50],
    categoricPurpleColor[50],
  ],
};
// No Target Set: Grey
// On target or below: Green
// Above target but within tolerance: Yellow
// Above target and not within tolerance: Red
// See https://concntric.atlassian.net/browse/CPE-3138
// and https://concntric.atlassian.net/browse/CPE-3130
const targetBudgetStatusColors = {
  [TargetBudgetStatus.noTargetSet]: {
    color: null,
    background: null,
  },
  [TargetBudgetStatus.onTarget]: {
    color: successGreenColor[800],
    background: successGreenColor[100],
  },
  [TargetBudgetStatus.aboveTarget]: {
    color: cautionRedColor[700],
    background: cautionRedColor[100],
  },
  [TargetBudgetStatus.aboveTargetWithinTolerance]: {
    color: categoricYellowColor[900],
    background: categoricYellowColor[200],
  },
  [TargetBudgetStatus.belowTarget]: {
    color: successGreenColor[800],
    background: successGreenColor[100],
  },
} as const;

const otherColors = {
  project: blueColor[700],
  average: '#C33D67',
  scenariosChip: scenarioColors,
  projectMembersChip: projectMembersColors,
  projectStatuses: projectStatusesColors,
  mitigationStatuses: mitigationStatusesColors,
  riskStatuses: riskStatusesColors,
  issueStatuses: issueStatusesColors,
  voiStatuses: voiStatusesColors,
  priorities: prioritiesColors,
  riskScore: riskScoreColors,
  targetBudgetStatus: targetBudgetStatusColors,
  getProjectComparisonColor: (index: number) =>
    projectComparisonColors.otherProjects[
      index % projectComparisonColors.otherProjects.length
    ],
  getProjectComparisonBackgroundColor: (index: number) =>
    projectComparisonColors.otherProjectsBackground[
      index % projectComparisonColors.otherProjectsBackground.length
    ],
  getDashboardProjectsColor: (index: number) =>
    dashboardProjectsColors[index % dashboardProjectsColors.length],
  getTVDColor: (index: number) => tvdColors[index % tvdColors.length],
  getComponentColor: (index: number, isUncategorizedComponent = false) =>
    isUncategorizedComponent
      ? greyColor[300]
      : componentColors[index % componentColors.length],
  getScenarioColors: (number: number) => scenarioColors[number % scenarioColors.length],
} as const;

const errorColor = {
  main: cautionRedColor[800],
};

const textColor = {
  primary: greyColor.main,
};

const chartColors = {
  blood: '#F25757',
  sun: '#FFD06C',
  leave: '#2BA23F',
} as const;

export const themeOptionsPalette = {
  primary: {
    main: blueColor.main,
  },
  secondary: {
    main: categoricPurpleColor.main, // just to put something
  },
  error: errorColor, // Review this color
  blue: blueColor,
  common: commonColors,
  other: otherColors,
  categoricYellow: categoricYellowColor,
  categoricPurple: categoricPurpleColor,
  grey: greyColor,
  orange: orangeColor,
  peackock: peackockColor,
  pumpkin: pumpkinColor,
  categoricGrape: categoricGrapeColor,
  fucsia: fucsiaColor,
  categoricApple: categoricAppleColor,
  categoricPink: categoricPinkColor,
  text: textColor,
  successGreen: successGreenColor,
  cautionRed: cautionRedColor,
  chart: chartColors,
} as const;

type CustomPalette = typeof themeOptionsPalette;

declare module '@mui/material' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface Color extends ICustomColor {}
}

declare module '@mui/material/styles/createPalette' {
  interface Palette {
    blue: CustomPalette['blue'];
    peackock: CustomPalette['peackock'];
    pumpkin: CustomPalette['pumpkin'];
    categoricPurple: CustomPalette['categoricPurple'];
    categoricGrape: CustomPalette['categoricGrape'];
    fucsia: CustomPalette['fucsia'];
    categoricApple: CustomPalette['categoricApple'];
    categoricPink: CustomPalette['categoricPink'];
    categoricYellow: CustomPalette['categoricYellow'];
    successGreen: CustomPalette['successGreen'];
    cautionRed: CustomPalette['cautionRed'];
    orange: CustomPalette['orange'];
    other: CustomPalette['other'];
    chart: CustomPalette['chart'];
  }
  interface PaletteOptions {
    blue?: CustomPalette['blue'];
    peackock?: CustomPalette['peackock'];
    pumpkin?: CustomPalette['pumpkin'];
    categoricPurple?: CustomPalette['categoricPurple'];
    categoricGrape?: CustomPalette['categoricGrape'];
    fucsia?: CustomPalette['fucsia'];
    categoricApple?: CustomPalette['categoricApple'];
    categoricPink?: CustomPalette['categoricPink'];
    categoricYellow?: CustomPalette['categoricYellow'];
    successGreen?: CustomPalette['successGreen'];
    cautionRed?: CustomPalette['cautionRed'];
  }
}

export const customPalette = createPalette(themeOptionsPalette as PaletteOptions);
