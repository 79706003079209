import { lazily } from 'react-lazily';
import { LogoutView } from './views/auth/users/logout';
import { ReportsView } from 'views/reports';
import {
  Layout,
  PrivateURL,
  ProcurementRequiredPermsRoute,
  PublicURL,
  UrlRoute,
} from 'Urls';
import { routePermissions } from 'routePermissions';
import { ProjectDashboardsView } from './views/project-dashboards';

const { ConceptLabView } = lazily(() => import('views/concept-lab'));

const { ConceptAnalysisDetailView } = lazily(
  () => import('views/analysis/[analysisId]/details'),
);

const { ConceptsComparePageView } = lazily(
  () => import('views/analysis/[analysisId]/concepts'),
);

const { ScenarioDetailsPageView } = lazily(
  () => import('views/analysis/[analysisId]/scenarios/[scenarioId]'),
);

const { CompareScenariosPageView } = lazily(
  () => import('views/analysis/[analysisId]/scenarios/index'),
);

const { VisionView } = lazily(() => import('views/projects/[projectId]/vision'));

const { ForeSiteView } = lazily(() => import('./views/projects/[projectId]/foresite'));
const { ValueOptionItemDetailsPage } = lazily(
  () => import('./views/projects/[projectId]/foresite/value-option-details/[voiId]'),
);
const { Estimate } = lazily(() => import('views/projects/[projectId]/estimate'));
const { EstimateImport } = lazily(
  () => import('views/projects/[projectId]/estimate-import'),
);
const { EstimateImportReview } = lazily(
  () => import('views/projects/[projectId]/estimate-import-review'),
);
const { ComponentsSetupView } = lazily(
  () => import('views/projects/[projectId]/components'),
);
const { ErrorView } = lazily(() => import('views/auth/error'));
const { CompleteSignUpView } = lazily(() => import('views/auth/users/complete'));
const { CalibrateByDivisionView } = lazily(
  () => import('views/projects/[projectId]/calibrate/division'),
);
const { CalibrateByProjectView } = lazily(
  () => import('views/projects/[projectId]/calibrate/index'),
);
const { ProjectNewOptions } = lazily(() => import('views/projects/new-options'));
const { ProjectBlankSetupView } = lazily(() => import('views/projects/blank'));
const { ProjectLegacySetupView } = lazily(() => import('views/projects/legacy/blank'));
const { ProjectLegacyUpdateView } = lazily(
  () => import('views/projects/legacy/[projectId]/index'),
);
const { ProjectLegacyComponentsView } = lazily(
  () => import('views/projects/legacy/[projectId]/components'),
);
const { ProjectLegacyEstimateView } = lazily(
  () => import('views/projects/legacy/[projectId]/estimate'),
);
const { ProjectDetailsView } = lazily(() => import('views/projects/[projectId]/index'));
const { ForbiddenView } = lazily(() => import('views/auth/ForbiddenView'));
const { TimelineSetupView } = lazily(() => import('views/projects/[projectId]/timeline'));
const { ProjectListView } = lazily(() => import('views/projects/index'));
const { NotFoundView } = lazily(() => import('views/auth/NotFound404View'));
const { PortfolioView } = lazily(() => import('views/portfolio'));
const { DashboardDetailView } = lazily(() => import('views/project-dashboards/[id]'));
const { MarkupTemplatesView } = lazily(() => import('views/markup-templates'));
const { LibraryView } = lazily(() => import('views/library'));
const { AddProjectMembersView } = lazily(
  () => import('views/projects/addProjectMembers'),
);
const { AreaSetupView } = lazily(() => import('views/projects/[projectId]/area-setup'));
const { TermsAndConditionsView } = lazily(() => import('views/terms-and-conditions'));
const { PrivacyPolicyView } = lazily(() => import('views/privacy-policy'));
const { RisksView } = lazily(() => import('views/projects/[projectId]/risks'));
const { ProjectOverview } = lazily(() => import('views/projects/[projectId]/overview'));
const { CustomCodesView } = lazily(() => import('views/custom-codes'));
const { ProcurementView } = lazily(
  () => import('views/projects/[projectId]/procurement'),
);
const { BidPackagesSetupView } = lazily(
  () => import('views/projects/[projectId]/procurement/wizard/bid-packages-setup'),
);
const { PurchasePlanView } = lazily(
  () => import('views/projects/[projectId]/procurement/wizard/purchase-plan'),
);
const { DocumentationView } = lazily(
  () => import('views/projects/[projectId]/procurement/wizard/documentation'),
);
const { BidPackageDevelopmentView } = lazily(
  () => import('views/projects/[projectId]/procurement/wizard/bid-package-development'),
);
const { CompanyProfilePage } = lazily(() => import('./views/companies/[companyId]'));
const { ManageUsers } = lazily(() => import('./views/users/manage'));
const { ManageUsersRolesRaw } = lazily(() => import('./views/users/manage-roles-raw'));
const { UserProfileView } = lazily(() => import('./views/users/[userId]'));
const { ResetPassword } = lazily(() => import('./views/auth/users/password'));
const { TargetValueDesignView } = lazily(() => import('views/TargetValueDesignView'));
const { BidPackageEditView } = lazily(
  () => import('views/projects/[projectId]/procurement/bid-package-details'),
);
const { BuildingConnected } = lazily(
  () => import('features/BuildingConnected/components/BuildingConnected'),
);
const { PublicApi } = lazily(() => import('views/public-api'));
const { PublicApiDocumentation } = lazily(() => import('views/public-api/documentation'));
const { ContextView } = lazily(() => import('./views/projects/[projectId]/context'));
const { IssuesView } = lazily(() => import('./views/projects/[projectId]/issues'));
const { ChangelogListView } = lazily(() => import('./views/changelog'));
const { CustomAttributesView } = lazily(() => import('./views/custom-attributes'));
const { UnitsOfMeasureView } = lazily(() => import('./views/units-of-measure'));
const { TenantSelectionView } = lazily(() => import('./views/auth/TenantSelectionView'));

const BasePublicRoutes: UrlRoute[] = [
  {
    name: 'Setup New Password',
    path: PublicURL.PASSWORD,
    component: ResetPassword,
  },
  {
    name: 'Complete Sign Up',
    path: PublicURL.COMPLETE_SIGNUP,
    component: CompleteSignUpView,
  },
  {
    name: 'Auth Error Page',
    path: PublicURL.AUTH_ERROR,
    component: ErrorView,
  },
  {
    name: 'Logout Page',
    path: PublicURL.LOGOUT,
    component: LogoutView,
  },
  {
    name: 'Tenant Selection',
    path: PublicURL.TENANT_SELECTION,
    component: TenantSelectionView,
  },
  {
    name: 'Privacy Policy',
    path: PublicURL.PRIVACY_POLICY,
    component: PrivacyPolicyView,
  },
];

export const PublicRoutes = BasePublicRoutes.map((pr) => ({
  ...pr,
  layout: Layout.PUBLIC,
}));

// These should be in order
const basePrivateRoutes: UrlRoute[] = [
  {
    name: 'Timeline',
    path: PrivateURL.TIMELINE,
    component: TimelineSetupView,
  },
  {
    name: 'Components',
    path: PrivateURL.COMPONENTS,
    component: ComponentsSetupView,
  },
  {
    name: 'Vision',
    path: PrivateURL.VISION,
    component: VisionView,
  },
  {
    name: 'Target Value Design',
    path: PrivateURL.TVD,
    component: TargetValueDesignView,
  },
  {
    name: 'Overview',
    path: PrivateURL.OVERVIEW,
    component: ProjectOverview,
  },
  {
    name: 'Value Option Item Details',
    path: PrivateURL.FORESITE_VOI_DETAILS,
    component: ValueOptionItemDetailsPage,
  },
  {
    name: 'ForeSite',
    path: PrivateURL.FORESITE,
    component: ForeSiteView,
  },
  {
    name: 'Estimate',
    path: PrivateURL.ESTIMATE,
    component: Estimate,
  },
  {
    name: 'Estimate Import',
    path: PrivateURL.ESTIMATE_IMPORT,
    component: EstimateImport,
  },
  {
    name: 'Estimate Import Review',
    path: PrivateURL.ESTIMATE_IMPORT_REVIEW,
    component: EstimateImportReview,
  },
  {
    name: 'Reporting',
    path: PrivateURL.REPORTS,
    component: ReportsView,
    requiredFeatureFlags: ['isReportingEnabled'],
  },
  {
    name: 'Context Page',
    path: PrivateURL.CONTEXT,
    component: ContextView,
  },
  {
    name: 'Issue Management',
    path: PrivateURL.ISSUE_MANAGEMENT,
    component: IssuesView,
    requiredFeatureFlags: ['isIssueManagementEnabled'],
  },
  {
    name: 'Manage members',
    path: PrivateURL.INVITE_MEMBERS,
    component: AddProjectMembersView,
  },
  {
    name: 'Calibrate Compare by division',
    path: PrivateURL.CALIBRATE_DIVISION,
    component: CalibrateByDivisionView,
  },
  {
    name: 'Calibrate',
    path: PrivateURL.CALIBRATE,
    component: CalibrateByProjectView,
  },
  {
    name: 'Program',
    path: PrivateURL.AREA_SETUP,
    component: AreaSetupView,
  },
  {
    name: 'Risk Register',
    path: PrivateURL.RISKS,
    component: RisksView,
  },
  {
    name: 'Procurement Wizard: Bid Packages Setup',
    path: PrivateURL.PROCUREMENT_WIZARD_BID_PACKAGES_SETUP,
    component: BidPackagesSetupView,
    ...ProcurementRequiredPermsRoute,
  },
  {
    name: 'Procurement Wizard: Purchase plan',
    path: PrivateURL.PROCUREMENT_WIZARD_PURCHASE_PLAN,
    component: PurchasePlanView,
    ...ProcurementRequiredPermsRoute,
  },
  {
    name: 'Procurement Wizard: Documentation',
    path: PrivateURL.PROCUREMENT_WIZARD_DOCUMENTATION,
    component: DocumentationView,
    ...ProcurementRequiredPermsRoute,
  },
  {
    name: 'Procurement Wizard: Bid Package Development',
    path: PrivateURL.PROCUREMENT_WIZARD_BID_PACKAGE_DEVELOPMENT,
    component: BidPackageDevelopmentView,
    ...ProcurementRequiredPermsRoute,
  },
  {
    name: 'Procurement Bid Package Details',
    path: PrivateURL.PROCUREMENT_BID_PACKAGE_DETAILS,
    component: BidPackageEditView,
    ...ProcurementRequiredPermsRoute,
  },
  {
    name: 'Procurement',
    path: PrivateURL.PROCUREMENT,
    component: ProcurementView,
    ...ProcurementRequiredPermsRoute,
  },
  {
    name: 'BuildingConnected',
    path: PrivateURL.BUILDING_CONNECTED,
    component: BuildingConnected,
  },
  {
    name: 'Manage users',
    path: PrivateURL.MANAGE_USERS_ROLES_RAW,
    component: ManageUsersRolesRaw,
  },
  {
    name: 'Account administration',
    path: PrivateURL.MANAGE_USERS,
    component: ManageUsers,
  },
  {
    name: 'Blank Project - Setup',
    path: PrivateURL.PROJECT_SETUP,
    component: ProjectBlankSetupView,
  },
  {
    name: 'New Project - Options',
    path: PrivateURL.PROJECT_NEW_OPTIONS,
    component: ProjectNewOptions,
  },
  {
    name: 'Legacy Project - Setup',
    path: PrivateURL.PROJECT_LEGACY_SETUP,
    component: ProjectLegacySetupView,
  },
  {
    name: 'Legacy Project - Components setup',
    path: PrivateURL.PROJECT_LEGACY_COMPONENTS,
    component: ProjectLegacyComponentsView,
  },
  {
    name: 'Legacy Project - Estimate upload',
    path: PrivateURL.PROJECT_LEGACY_ESTIMATE,
    component: ProjectLegacyEstimateView,
  },
  {
    name: 'Legacy Project - Update',
    path: PrivateURL.PROJECT_LEGACY_UPDATE,
    component: ProjectLegacyUpdateView,
  },
  {
    name: 'Details',
    path: PrivateURL.PROJECT_UPDATE,
    component: ProjectDetailsView,
  },
  {
    name: 'Projects',
    path: PrivateURL.PROJECT_LIST,
    component: ProjectListView,
  },
  {
    name: 'Company Profile',
    path: PrivateURL.COMPANY_PROFILE,
    component: CompanyProfilePage,
  },
  {
    name: 'User Profile',
    path: PrivateURL.USER_PROFILE,
    component: UserProfileView,
  },
  {
    name: 'Portfolio',
    path: PrivateURL.PORTFOLIO,
    component: PortfolioView,
  },
  {
    name: 'Portfolio',
    path: PrivateURL.PROJECT_DASHBOARD_PORTFOLIO,
    component: PortfolioView,
  },
  {
    name: 'Project Dashboard Detail',
    path: PrivateURL.PROJECT_DASHBOARD_DETAIL,
    component: DashboardDetailView,
  },
  {
    name: 'Project Dashboards',
    path: PrivateURL.PROJECT_DASHBOARD_LIST,
    component: ProjectDashboardsView,
  },
  {
    name: 'Markup Templates',
    path: PrivateURL.MARKUP_TEMPLATES,
    component: MarkupTemplatesView,
  },
  {
    name: 'Concept Hub',
    path: PrivateURL.CONCEPT_LAB,
    component: ConceptLabView,
  },
  {
    name: 'Concept Hub Analysis',
    path: PrivateURL.CONCEPT_LAB_ANALYSIS_BY_ID,
    component: ConceptAnalysisDetailView,
  },
  {
    name: 'Analysis Concepts Compare',
    path: PrivateURL.CONCEPT_LAB_CONCEPTS,
    component: ConceptsComparePageView,
  },
  {
    name: 'Scenario',
    path: PrivateURL.CONCEPT_HUB_SCENARIO_BY_ID,
    component: ScenarioDetailsPageView,
  },
  {
    name: 'Compare Scenarios',
    path: PrivateURL.CONCEPT_HUB_SCENARIOS,
    component: CompareScenariosPageView,
  },
  {
    name: 'Forbidden',
    path: PrivateURL.FORBIDDEN_VIEW,
    component: ForbiddenView,
  },
  {
    name: 'ForeSite Library',
    path: PrivateURL.FORESITE_LIBRARY,
    component: LibraryView,
  },
  {
    name: 'Terms and conditions',
    path: PrivateURL.TERMS_AND_CONDITIONS,
    component: TermsAndConditionsView,
  },
  {
    name: 'Custom Formats',
    path: PrivateURL.CUSTOM_CODES,
    component: CustomCodesView,
  },
  {
    name: 'Public API Documentation',
    path: PrivateURL.PUBLIC_API_DOCUMENTATION,
    component: PublicApiDocumentation,
    requiredTenantFlags: ['public_api'],
  },
  {
    name: 'Public API',
    path: PrivateURL.PUBLIC_API,
    component: PublicApi,
    requiredTenantFlags: ['public_api'],
  },
  {
    name: 'Changelog List',
    path: PrivateURL.CHANGELOG_LIST,
    component: ChangelogListView,
  },
  {
    name: 'Custom Attributes',
    path: PrivateURL.CUSTOM_ATTRIBUTES,
    component: CustomAttributesView,
    requiredTenantFlags: ['enable_custom_attributes'],
  },
  {
    name: 'Units of Measure',
    path: PrivateURL.UNITS_OF_MEASURE,
    component: UnitsOfMeasureView,
  },
  {
    name: 'Page Not Found',
    path: '/*',
    component: NotFoundView,
  },
];

export const PrivateRoutes = basePrivateRoutes.map((pr) => ({
  ...pr,
  layout: Layout.PRIVATE,
  requiredPermission: routePermissions[pr.path],
}));
