import { useQuery, useMutation, useQueryClient, QueryKey } from '@tanstack/react-query';
import { ApiService } from 'api/ApiService';
import { Resources } from 'api/Resources';
import { useProject } from 'features/Projects/hook/useProject';
import { Setting, SettingConfigKey } from 'types/Setting';
import { queryKeys } from 'utils/reactQuery';
import { useMemo } from 'react';
import { isEmpty } from 'lodash-es';

export const useSetting = <K extends Setting>(
  key: SettingConfigKey,
  defaultValue: K['config'] = {},
  queriesKeysToInvalidate: QueryKey[] = [],
  options?: { onSettled: () => void },
  // A single project may have settings for multiple resource instances, so we need to identify the setting
  resourceId?: number,
  enabled = true,
) => {
  const { project } = useProject();
  const projectId = project?.id;
  const queryClient = useQueryClient();
  const queryKey = queryKeys.project(projectId).settings(key, resourceId).base;
  const mutationKey = queryKeys.project(projectId).settings(key, resourceId).mutation;
  const settingKey = `${key}${resourceId ?? ''}`;

  // Function to get setting identified by key.
  function load() {
    return ApiService.get(
      `${Resources.SETTINGS.replace('<str:key>', settingKey)}?project_id=${projectId}`,
    ).catch((error) => {
      if (error.cause?.response?.status === 404) {
        return {
          data: { config: defaultValue },
        };
      }
      throw error;
    });
  }

  // Function to create a setting's value identified by key.
  function create(payload: Record<string, unknown>) {
    return ApiService.post(Resources.SETTINGS.replace('<str:key>', ''), payload);
  }

  // Function to update a setting's value identified by key.
  function update(payload: Record<string, unknown>) {
    return ApiService.put(
      `${Resources.SETTINGS.replace('<str:key>', setting?.key ?? '')}?project_id=${
        setting?.project ?? ''
      }`,
      payload,
    );
  }

  // placeholderData is not stored on the cache so if the query fails (e.g. 404 server error)
  // we'll end up with an undefined value. We intend the initial data to be the default value so
  // we need to use initialData instead – which is turn is stored on the cache:

  // Set a stale time big enough that it will not be refetched frequently but less than Infinity
  // so we can force a refetch.
  const staleTime = 60 * 60 * 24 * 1000; // 24 hours
  // Force refreshing the initial data as soon as the hook mounts.
  const initialDataUpdatedAt = new Date().getTime() - staleTime;
  const initialData = useMemo(
    () => ({ id: 0, key: '', project: 0, config: defaultValue }) as unknown as K,
    [defaultValue],
  );
  const { data: setting, ...query } = useQuery({
    queryKey,
    queryFn: () =>
      load().then((res) => {
        if (isEmpty(res.data)) {
          return initialData;
        } else {
          return { ...res.data, config: { ...defaultValue, ...res.data.config } } as K;
        }
      }),
    initialData,
    staleTime,
    initialDataUpdatedAt,
    enabled,
  });

  const { mutate: saveSetting, ...mutation } = useMutation({
    mutationFn: (config: Partial<K['config']>) =>
      (setting?.id ? update : create)({
        project: projectId,
        key: settingKey,
        config,
      }).then((res) => res.data as K),
    mutationKey,
    onMutate: (config) => {
      // Optimistic update and cache old values
      const oldData = queryClient.getQueryData(queryKey);
      queryClient.setQueryData(queryKey, {
        ...setting,
        project: projectId,
        key: settingKey,
        config,
      });
      return oldData;
    },
    onSuccess: (data) => {
      queryClient.setQueryData(queryKey, data);
    },
    onError: (_err, _config, oldData) => {
      // Rollback the optimistic update if the mutation fails
      queryClient.setQueryData(queryKey, oldData);
    },
    onSettled: () => {
      // Always refetch after error or success
      queryClient.invalidateQueries({ queryKey });
      queriesKeysToInvalidate.forEach((queryKey) => {
        queryClient.invalidateQueries({ queryKey });
      });
      options?.onSettled();
    },
  });

  return {
    saveSetting,
    query,
    mutation,
    mutationKey,
    setting: setting?.config as K['config'],
    isDefaultSetting: !setting?.id,
  };
};
