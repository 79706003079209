import { atom, useAtom } from 'jotai';
import { useCallback } from 'react';
import { History, PrivateURL } from 'Urls';
import { ConceptHubScenario } from '../type';

const analysisIdAtom = atom<number | 'new' | undefined>(undefined);
analysisIdAtom.debugLabel = 'analysisIdAtom';

const variantIdAtom = atom<number | null>(null);
variantIdAtom.debugLabel = 'variantIdAtom';

type VariantViewMode = 'VIEW' | 'EDIT' | 'CREATE';
const variantFlyoutAtom = atom<{ isOpen: boolean; viewMode: VariantViewMode }>({
  isOpen: false,
  viewMode: 'VIEW',
});
variantFlyoutAtom.debugLabel = 'variantFlyoutAtom';

export const useAnalysisFlyoutDetails = () => {
  const [analysisDetailId, setAnalysisDetailId] = useAtom(analysisIdAtom);

  const closeAnalysisModal = useCallback(() => {
    setAnalysisDetailId(undefined);
  }, [setAnalysisDetailId]);

  return {
    analysisDetailId,
    closeAnalysisModal,
    setAnalysisDetailId,
  };
};

export const useSelectedVariantId = () => {
  const [selectedVariantId, setSelectedVariantId] = useAtom(variantIdAtom);

  return {
    selectedVariantId,
    setSelectedVariantId,
  };
};

export const useVariantFlyout = () => {
  const [variantFlyoutState, setVariantFlyoutState] = useAtom(variantFlyoutAtom);

  const openVariantFlyout = useCallback(
    (viewMode: VariantViewMode) => {
      setVariantFlyoutState({ isOpen: true, viewMode });
    },
    [setVariantFlyoutState],
  );

  const closeVariantFlyout = useCallback(() => {
    setVariantFlyoutState({ isOpen: false, viewMode: 'VIEW' });
  }, [setVariantFlyoutState]);

  return {
    ...variantFlyoutState,
    openVariantFlyout,
    closeVariantFlyout,
  };
};

const isScenarioFlyoutAtom = atom<boolean>(false);

export const useScenarioFlyout = () => {
  const [isScenarioFlyoutOpen, setIsScenarioFlyoutOpen] = useAtom(isScenarioFlyoutAtom);

  const openScenarioFlyout = useCallback(() => {
    setIsScenarioFlyoutOpen(true);
  }, [setIsScenarioFlyoutOpen]);

  const closeScenarioFlyout = useCallback(() => {
    setIsScenarioFlyoutOpen(false);
  }, [setIsScenarioFlyoutOpen]);
  return {
    isOpen: isScenarioFlyoutOpen,
    openScenarioFlyout,
    closeScenarioFlyout,
  };
};

const openAddCompareModalAtom = atom<'PROJECTS' | 'COMPONENTS' | ''>('');

export const useOpenCompareModalState = () => {
  const [compareModalOpen, setCompareModalOpen] = useAtom(openAddCompareModalAtom);

  return { compareModalOpen, setCompareModalOpen };
};

const variantSettingsFlyoutAtom = atom<{
  isOpen: boolean;
  settings: Record<string, boolean>;
}>({
  isOpen: false,
  settings: {
    isPlaygroundColumnEnabled: true,
  },
});
variantSettingsFlyoutAtom.debugLabel = 'variantSettingFlyoutAtom';

export const useVariantSettingsFlyoutDetails = () => {
  const [variantSettings, setVariantSettings] = useAtom(variantSettingsFlyoutAtom);

  const openVariantSettingsModal = useCallback(() => {
    setVariantSettings({ settings: variantSettings.settings, isOpen: true });
  }, [setVariantSettings, variantSettings]);

  const closeVariantSettingsModal = useCallback(() => {
    setVariantSettings({ settings: variantSettings.settings, isOpen: false });
  }, [setVariantSettings, variantSettings]);

  const updateVariantSettings = useCallback(
    (key: string, value: boolean) => {
      setVariantSettings({
        isOpen: variantSettings.isOpen,
        settings: { ...variantSettings.settings, [key]: value },
      });
    },
    [setVariantSettings, variantSettings],
  );

  return {
    ...variantSettings,
    openVariantSettingsModal,
    closeVariantSettingsModal,
    updateVariantSettings,
  };
};

const normalizeByCustomCoefficientsAtom = atom<boolean>(false);
const normalizeByTimeAtom = atom<boolean>(false);
const normalizeByLocationAtom = atom<boolean>(false);
const plotNormalizationAtom = atom<boolean>(false);
const isNormalizationFlyoutOpenAtom = atom<boolean>(false);

export const useVariantNormalization = () => {
  const [normalizeByCustomCoefficients, setNormalizeByCustomCoefficients] = useAtom(
    normalizeByCustomCoefficientsAtom,
  );
  const [normalizeByTime, setNormalizeByTime] = useAtom(normalizeByTimeAtom);
  const [normalizeByLocation, setNormalizeByLocation] = useAtom(normalizeByLocationAtom);
  const [plotNormalizationFactors, setPlotNormalization] = useAtom(plotNormalizationAtom);
  const [isNormalizationFlyoutOpen, setIsNormalizationFlyoutOpen] = useAtom(
    isNormalizationFlyoutOpenAtom,
  );

  const toggleNormalizeByCustomCoefficients = useCallback(
    () => setNormalizeByCustomCoefficients(!normalizeByCustomCoefficients),
    [normalizeByCustomCoefficients, setNormalizeByCustomCoefficients],
  );
  const toggleNormalizeByTime = useCallback(
    () => setNormalizeByTime(!normalizeByTime),
    [normalizeByTime, setNormalizeByTime],
  );
  const toggleNormalizeByLocation = useCallback(
    () => setNormalizeByLocation(!normalizeByLocation),
    [normalizeByLocation, setNormalizeByLocation],
  );
  const togglePlotNormalizationFactors = useCallback(
    () => setPlotNormalization(!plotNormalizationFactors),
    [plotNormalizationFactors, setPlotNormalization],
  );

  return {
    isNormalizationFlyoutOpen,
    normalizeByCustomCoefficients,
    normalizeByLocation,
    normalizeByTime,
    plotNormalizationFactors,
    setIsNormalizationFlyoutOpen,
    toggleNormalizeByCustomCoefficients,
    toggleNormalizeByTime,
    toggleNormalizeByLocation,
    togglePlotNormalizationFactors,
  };
};

const componentSetsModalAtom = atom<boolean>(false);
componentSetsModalAtom.debugLabel = 'componentSetsModalAtom';

export const useComponentSetsModal = () => {
  const [isComponentSetsModalOpen, setIsComponentSetsModalOpen] =
    useAtom(componentSetsModalAtom);

  return {
    isComponentSetsModalOpen,
    setIsComponentSetsModalOpen,
  };
};

const scenarioIdAtom = atom<number | null>(null);
scenarioIdAtom.debugLabel = 'scenarioIdAtom';

export const useSelectedScenarioId = () => {
  const [selectedScenarioId, setSelectedScenarioId] = useAtom(scenarioIdAtom);

  const handleSelectScenario = (
    scenario: Pick<ConceptHubScenario, 'id'>,
    analysisId: number,
  ) => {
    if (scenario.id !== selectedScenarioId) {
      setSelectedScenarioId(scenario.id);
      History.push(
        PrivateURL.CONCEPT_HUB_SCENARIO_BY_ID.replace(
          ':analysisId',
          String(analysisId),
        ).replace(':scenarioId', String(scenario.id)),
      );
    }
  };

  return {
    selectedScenarioId,
    setSelectedScenarioId,
    handleSelectScenario,
  };
};
