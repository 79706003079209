import {
  Mutation,
  MutationCache,
  Query,
  QueryCache,
  QueryClient,
} from '@tanstack/react-query';
import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister';
import { persistQueryClient } from '@tanstack/react-query-persist-client';

import axios from 'axios';
import { RiskOrDraft } from 'types/Risk';
import { isEqual, pick } from 'lodash';
import { CostGroupStandard } from 'types/CostGroup';
import { Dimension } from 'types/ForeSite';
import { SettingConfigKey } from 'types/Setting';
import { TagType } from 'types/Common';
import { ItemDetailsId } from 'features/Foresite/types/ui';
import { IssueOrDraft } from 'types/Issue';
import { RelatedItemType } from 'features/Issues/components/types';
import { CalibrateByComponentSettings } from 'features/Calibrate/components/CalibrateByComponent/helpers';
import log from 'loglevel';
import { ModuleName } from 'types/User';
import { PermissionType } from 'features/Auth/Permissions';

import { DashboardCustomMetricFeature, ProjectDashboardDetailsId } from 'types/Dashboard';
import { CustomAttributeModelEntity } from 'types/CustomAttributes';
import * as Sentry from '@sentry/react';
import { MilestoneEstimateUploadStatus } from 'types/Estimate';

const getAxiosContextInfo = (error: Error) =>
  error?.cause && {
    ...pick(error.cause, ['code', 'name', 'message', 'status']),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    config: pick((error.cause as any)?.config, [
      'baseURL',
      'method',
      'timeout',
      'url',
      'withCredentials',
    ]),
  };

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: (failureCount, error) => {
        if (failureCount >= 3) return false;
        if (error instanceof Error && axios.isAxiosError(error.cause)) {
          return ![404, 409, 400].includes(error.cause.response?.status as number);
        }
        return true;
      },
      gcTime: Infinity,
    },
    dehydrate: {
      shouldDehydrateMutation: (_mutation: Mutation) => false,
      shouldDehydrateQuery: (_query: Query) => false,
    },
  },
  queryCache: new QueryCache({
    onError: (error) => {
      log.error(`Something went wrong with queryCache: ${error.message}`);
      Sentry.withScope((scope) => {
        scope.setExtra('Axios Context', getAxiosContextInfo(error));
        Sentry.captureException(error);
      });
    },
  }),
  mutationCache: new MutationCache({
    onError: (error) => {
      log.error(`Something went wrong with mutationCache: ${error.message}`);
      Sentry.withScope((scope) => {
        scope.setExtra('Axios Context', getAxiosContextInfo(error));
        Sentry.captureException(error);
      });
    },
  }),
});

export const queryKeys = {
  permissionRoles: ['permissionRoles'],
  accessScopes: ['accessScopes'],
  companyUsers: ['companyUsers'],
  permissionRolePermissions: (roleId?: number) => ['permissionRolePermissions', roleId],
  projects: {
    all: ['projects'],
    lean: ['projects', 'lean'],
  },
  issueTypes: ['issueTypes'],
  issueSubtypes: ['issueSubtypes'],
  issueOptions: ['issueOptions'],
  checkUser: ['checkUser'],
  templates: ['templates'],
  project: (projectId: number | null | undefined) => {
    const base = ['project', projectId] as const;
    return {
      details: base,
      milestones: [...base, 'milestones'],
      risks: [...base, 'risks'],
      items: [...base, 'items'],
      components: [...base, 'components'],
      componentAreas: [...base, 'componentAreas'],
      componentsUsage: [...base, 'componentsUsage'],
      scenarios: [...base, 'scenarios'],
      bidCostGroupsWithMilestone: [...base, 'bidCostGroupsWithMilestone'],
      bidPackages: (bidPackageId?: number | null) => [
        ...base,
        'bidPackages',
        bidPackageId,
      ],
      procurementSpecifications: [...base, 'procurementSpecifications'],
      procurementStatuses: [...base, 'procurementStatuses'],
      bidPackagePullPlan: (bidPackageId: number | null | undefined) => [
        ...base,
        'bidPackagePullPlan',
        bidPackageId,
      ],
      bidPackagePriorities: [...base, 'bidPackagePriorities'],
      missingData: [...base, 'missingData'],
      timelinePhases: [...base, 'timelinePhases'],
      timelineActivities: [...base, 'timelineActivities'],
      timelineContractEvents: [...base, 'timelineContractEvents'],
      issues: [...base, 'issues'],
      issueInsights: [...base, 'issues', 'insights'],
      relatedIssues: ({
        entityType,
        entityId,
      }: {
        entityType: RelatedItemType;
        entityId: number;
      }) => [...base, entityType, entityId, 'issues'],
      timelineActivityGroups: [...base, 'timelineActivityGroups'],
      foresiteCost: [...base, 'foresiteCost'],
      foresiteProgram: [...base, 'foresiteProgram'],
      foresiteSummary: [...base, 'foresiteSummary'],
      totalsSummary: [...base, 'totalsSummary'],
      costBreakdownInfo: (dimension: string) => [...base, dimension],
      tvdForesite: [...base, 'tvdForesite'],
      tvdForesiteBudgetAdjusted: [...base, 'tvdForesite', 'budgetAdjusted'],
      tvdForesiteTargetDimension: (
        targetDimension: Dimension,
        perSquareFootQueryParam: string,
      ) => [...base, 'tvdForesite', targetDimension, perSquareFootQueryParam],
      tvdCalibrate: [...base, 'tvdCalibrate'],
      calibrateByProject: () => {
        const calibrateByProjectBase = [...base, 'calibrateByProject'];
        return {
          base: calibrateByProjectBase,
          detail: (
            isByComponent: boolean,
            calibrateByComponentSettings: CalibrateByComponentSettings,
          ) => {
            return [
              ...calibrateByProjectBase,
              isByComponent ? calibrateByComponentSettings : {},
            ];
          },
        };
      },
      calibrateByDivision: [...base, 'calibrateByDivision'],
      calibrateComponentsSummary: [...base, 'calibrateComponentsSummary'],
      calibrateByDivisionComparison: (
        costGroupStandard: CostGroupStandard | string,
        compareProjectIds?: number[],
      ) => {
        const calibrateByDivisionComparisonBase = [
          ...base,
          'calibrateByDivisionComparison',
          costGroupStandard,
          compareProjectIds,
        ];
        return {
          base: calibrateByDivisionComparisonBase,
          detail: (
            isByComponent: boolean,
            calibrateByComponentSettings: CalibrateByComponentSettings,
          ) => {
            return [
              ...calibrateByDivisionComparisonBase,
              isByComponent ? calibrateByComponentSettings : {},
            ];
          },
        };
      },
      calibrateByDivisionNormalization: (
        compareProjectIds: number[] | undefined,
        normalizeByTime: boolean | undefined,
        normalizeByLocation: boolean | undefined,
        dimension: string | undefined,
        formatId: string | undefined,
      ) => [
        ...base,
        'calibrateByDivisionNormalization',
        compareProjectIds,
        normalizeByTime,
        normalizeByLocation,
        dimension,
        formatId,
      ],
      normalizationTimes: (otherProjectsIds: number[]) => [
        ...base,
        'normalizationTimes',
        ...otherProjectsIds,
      ],
      customNormalization: (otherProjectsIds: number[]) => [
        ...base,
        'customNormalization',
        ...otherProjectsIds,
      ],
      calibrateLineItems: (
        otherProjectsIds: number[],
        division: number | null,
        isByComponent: boolean,
        calibrateByComponentSettings: CalibrateByComponentSettings,
      ) => [
        ...base,
        'lineItems',
        ...otherProjectsIds,
        division,
        isByComponent ? calibrateByComponentSettings : {},
      ],
      dimensionMemberParams: (dimensionType: string | undefined) => {
        const dimensionBase = [
          ...base,
          'TvdDimensionMemberParams',
          dimensionType,
        ] as const;
        return {
          base: dimensionBase,
          withId: (dimensionId: number | undefined) => [...dimensionBase, dimensionId],
        } as const;
      },

      dimensionsUsage: (milestoneId?: number) => [
        ...base,
        'dimensionsUsage',
        ...(milestoneId ? [milestoneId] : []),
      ],
      members: [...base, 'members'],
      settings: (key: SettingConfigKey, resourceId?: number) => {
        const settingsBase = [
          ...base,
          'settings',
          key,
          ...(resourceId ? [resourceId] : []),
        ];
        return {
          base: settingsBase,
          mutation: [...settingsBase, 'mutation'],
        };
      },
      itemsReportInfo: (queryParams: string[]) => [...base, ...queryParams],
      contextWidgets: [...base, 'contextWidgets'],
      autodeskViewerDesignFiles: [...base, 'autodeskViewerDesignFiles'],
      milestoneEstimateUpload: (milestoneEstimateUploadId: number | null | undefined) => [
        ...base,
        'milestoneEstimateUpload',
        milestoneEstimateUploadId,
      ],
      moduleMembers: (module: ModuleName, permissions?: PermissionType[]) =>
        [...base, 'moduleMembers', module].concat(
          ...(permissions ? [permissions.toSorted().join(',')] : []),
        ),
      tags: (type: TagType) => [...base, 'tags', type],
    } as const;
  },
  calibrate: {
    availableProjectsForComparison: (
      excludeIds?: (number | null)[],
      defintionId?: number,
    ) => ['available-comparison-projects', excludeIds?.sort().toString(), defintionId],
  },
  autodesk: {
    accProject: (accData: { accHubId: string; accProjectId: string } | null) => [
      'acc',
      'project',
      accData?.accHubId,
      accData?.accProjectId,
    ],
  },
  legacyProject: (legacyProjectId: number | null | undefined) => {
    const base = ['legacyProject', legacyProjectId] as const;
    return {
      milestone: [...base, 'milestone'],
    } as const;
  },
  risk: (riskId: RiskOrDraft['id'] | undefined) => {
    const base = ['risk', riskId] as const;
    return {
      details: base,
      comments: [...base, 'comments'],
      history: [...base, 'history'],
    } as const;
  },
  issue: (issueId: IssueOrDraft['id'] | undefined) => {
    const base = ['issue', issueId] as const;
    return {
      details: base,
      comments: [...base, 'comments'],
      history: [...base, 'history'],
    } as const;
  },
  milestone: (milestoneId: number | null | undefined) => {
    const base = ['milestone', milestoneId] as const;
    return {
      estimateMarkups: [...base, 'estimateMarkups'],
      subtotals: (dimensionMember?: number) => [
        ...base,
        'subtotals',
        ...(dimensionMember ? [dimensionMember] : []),
      ],
      lineItems: [...base, 'lineItems'],
      ownerCosts: [...base, 'ownerCosts'],
      formatDefinition: [...base, 'formatDefinition'],
      ownerCost: (ownerCostId: number | null | undefined) => [
        ...base,
        'ownerCosts',
        ownerCostId,
      ],
      estimateUploads: (status?: MilestoneEstimateUploadStatus[]) => {
        const uploadBase = [...base, 'estimateUploads', ...(status ?? [])] as const;
        return {
          base: uploadBase,
          aiAssistant: (uploadId: number) => [...uploadBase, 'ai-assistant', uploadId],
        } as const;
      },
      comments: [...base, 'comments'],
    } as const;
  },
  timelineActivity: (timelineActivityId: number | null | undefined) => {
    const base = ['timelineActivity', timelineActivityId] as const;
    return {
      comments: [...base, 'comments'],
    } as const;
  },
  item: (itemId?: ItemDetailsId | null) => {
    const base = ['item', itemId] as const;
    return {
      details: base,
      subItems: [...base, 'subItems'],
      mutuallyExclusiveItemsGroups: [...base, 'mutuallyExclusiveItemsGroups'],
      mutuallyExclusiveItemsGroupsMutation: [
        ...base,
        'mutuallyExclusiveItemsGroupsMutation',
      ],
      comments: [...base, 'comments'],
      markups: [...base, 'markups'],
      history: [...base, 'history'],
    } as const;
  },
  subItem: (subItemId?: number) => {
    const base = ['subItem', subItemId] as const;
    return {
      details: base,
    } as const;
  },
  approvalItems: (approvalItemIds?: number[] | null) => {
    const base = ['approvalItem', approvalItemIds?.sort().toString()] as const;
    return {
      details: base,
      subItems: [...base, 'subItems'],
    } as const;
  },
  idea: (ideaId?: number | false | 'new' | null) => {
    const base = ['idea', ideaId] as const;
    return {
      details: base,
    } as const;
  },
  bidPackage: (bidPackageId: number | null) => {
    const base = ['bidPackage', bidPackageId] as const;
    return {
      details: base,
      comments: [...base, 'comments'],
      links: [...base, 'links'],
    } as const;
  },
  companyProfile: ['companyProfile'],
  company: (companyId: number | undefined) => {
    const base = ['company', companyId] as const;
    return {
      customCostGroupDefinitions: () => {
        const customCostGroupDefinitionsBase = [
          ...base,
          'customCostGroupDefinitions',
        ] as const;
        return {
          base: customCostGroupDefinitionsBase,
          details: (
            includeDetails: boolean,
            projectId: number | null | undefined = null,
            milestoneId: number | null | undefined = null,
            includeChildren?: boolean,
          ) => [
            ...customCostGroupDefinitionsBase,
            includeDetails,
            ...(projectId ? [projectId] : []),
            ...(milestoneId ? [milestoneId] : []),
            !!includeChildren,
          ],
        };
      },
      customCostGroupDefinition: (definition: number | null | undefined) => [
        ...base,
        'customCostGroupDefinition',
        definition,
      ],
      ideas: [...base, 'ideas'],
    } as const;
  },
  projectDetailField: (fieldType?: string) => [
    'projectCustomField',
    fieldType ? fieldType.toLowerCase() : 'all',
  ],
  attachmentsByEntity: (entityName: string, entityId: number) => [
    entityName,
    entityId,
    'attachments',
  ],
  riskCategories: ['riskCategories'],
  deliveryContractTypes: ['deliveryContractTypes'],
  companyLogo: (currentUserEmail: string | undefined) => [
    'companyLogo',
    currentUserEmail,
  ],
  costGroup: (standard: CostGroupStandard) => ['costGroup', standard],
  categories: ['categories'],
  favoriteCategories: ['favoriteCategories'],
  recentCategories: ['recentCategories'],
  itemUnits: ['itemUnits'],
  componentBaseUnits: ['componentBaseUnits'],
  componentBaseUnitCount: (componentId: number | undefined) => {
    const base = ['componentBaseUnitCount', componentId] as const;
    return {
      details: base,
    } as const;
  },
  notifications: ['notifications'],
  projectsBudgetDelta: (projectIds: number[]) => [
    'projectsBudgetDelta',
    projectIds.join(),
  ],
  conceptLab: ['conceptLabAnalysis'],
  conceptAnalysis: (id: number | null | undefined) => {
    const base = ['conceptAnalysis', id];
    return {
      details: base,
      conceptsCostGroupsSummary: [...base, 'concepts-cost-groups-summary'],
      conceptsCostProjectsSummary: [...base, 'concepts-projects-summary'],
      scenarios: [...base, 'concepts-scenarios'],
      scenario: (scenario: number) => {
        const base = ['scenario', scenario];
        return {
          details: base,
          markups: [...base, 'markups'],
          ownerCosts: [...base, 'owner-costs'],
        };
      },
    };
  },
  conceptAnalysisVariant: (id: number | null | undefined) => [
    'conceptAnalysisVariant',
    id,
  ],
  conceptAnalysisVariantSummary: (id: number | null | undefined) => [
    'conceptAnalysisVariantSummary',
    id,
  ],
  costGroupSystems: ['costGroupSystems'],
  costGroupCategories: ['costGroupCategories'],
  tenantFeatureFlags: ['tenantFeatureFlags'],
  projectGroups: ['projectGroups'],
  projectDashboards: ['projectDashboards'],
  dashboardMetrics: ['dashboardMetrics'],
  dashboardPermissions: ['dashboardPermissions'],
  projectDashboardCustomMetricDefinitions: (
    feature: DashboardCustomMetricFeature | null,
  ) => ['projectDashboardCustomMetricDefinitions', feature],
  projectDashboard: (id: ProjectDashboardDetailsId | null | undefined) => {
    const base = ['projectDashboards', id];
    return {
      details: base,
      aiAssistant: {
        base: [...base, 'aiAssistant'],
        details: (assistantId?: number | undefined) => [
          ...base,
          'aiAssistant',
          assistantId,
        ],
      },
      comments: [...base, 'comments'],
      metricSettings: [...base, 'metrics'],
      metric: {
        summary: (projects: number[]) => [...base, 'metric', 'summary', projects],
        projects: ({ sort }: { sort: string }) => [...base, 'metric', 'projects', sort],
        feeOverTime: (projects: number[]) => [...base, 'metric', 'feeOverTime', projects],
        customMetricData: (customMetricId: number) => {
          const customMetricBase = [
            ...base,
            'metric',
            'customMetricData',
            customMetricId,
          ];
          return {
            base: customMetricBase,
            projects: (projects: number[]) => [...customMetricBase, projects],
          };
        },
        contingencyOverTime: (projects: number[]) => [
          ...base,
          'metric',
          'contingencyOverTime',
          projects,
        ],
        estimateSummary: [...base, 'metric', 'estimateSummary'],
        projectsScope: ({
          sort,
          selectedProjects,
        }: {
          sort: string;
          selectedProjects: number[];
        }) => [...base, 'metric', 'projectsScope', selectedProjects, sort],
        valueOptionGeneration: [...base, 'metric', 'valueOptionGeneration'],
        portfolioDiversity: (projects: number[]) => [
          ...base,
          'metric',
          'portfolioDiversity',
          projects,
        ],
        risksSummary: (projects: number[]) => [
          ...base,
          'metric',
          'risksSummary',
          projects,
        ],
        issuesSummary: (projects: number[]) => [
          ...base,
          'metric',
          'issuesSummary',
          projects,
        ],
        milestonesSummary: (projects: number[]) => [
          ...base,
          'metric',
          'milestonesSummary',
          projects,
        ],
        healthMetrics: (projects: number[]) => [
          ...base,
          'metric',
          'healthMetrics',
          projects,
        ],
      },
    };
  },
  customAttributes: ({
    entity,
    entityId,
  }: {
    entity: CustomAttributeModelEntity;
    entityId: number | null | undefined;
  }) => {
    return {
      attributesConfigurations: [
        'customAttributes',
        'attributesConfigurations',
        entity,
        entityId,
      ],
      values: ['customAttributes', 'values', entity, entityId],
    };
  },
  calibrateCustomAttributesValues: ({
    entity,
    entitiesId,
  }: {
    entity: CustomAttributeModelEntity | undefined;
    entitiesId: number[];
  }) => {
    return ['customAttributes', 'values', entity, entitiesId];
  },
  customAttribute: ({ id }: { id: number | null | undefined }) => {
    return ['customAttribute', id];
  },
  roles: ['roles'],
  genericMappers: (genericMapperId?: number | null) => [
    'genericMappers',
    ...(genericMapperId ? [genericMapperId] : []),
  ],
  apiKeys: {
    base: ['apiKeys'],
    get permissionsAndAccessScopes() {
      return [...this.base, 'permissionsAndAccessScopes'];
    },
  },
  apiKey: (prefix: string) => ['apiKey', prefix],
  autodesk2LeggedToken: ['autodesk2LeggedToken'],
  publicApiSpec: ['publicApiSpec'],
  markupTemplates: ['markupTemplates'],
  savedFilters: (entity: string) => ['savedFilters', entity],
  customAttributeGroups: ['customAttributeGroups'],
  normalizationByTime: (
    cityId: number | null,
    startYear: number | null,
    endYear: number | null,
  ) => ['normalize-by-time', cityId, startYear, endYear],
  normalizationByLocations: (referenceCity: number, comparedCities: number[]) => [
    'normalize-by-location',
    referenceCity,
    comparedCities,
  ],
  customNormalizationByEntity: (entity: string, id: number) => [
    'custom-normalization-by-entity',
    entity,
    id,
  ],
} as const;

const syncStoragePersister = createSyncStoragePersister({
  storage: window.localStorage,
  key: 'REACT_QUERY_OFFLINE_CACHE_01',
});

const queryKeysToPersist = [
  queryKeys.categories,
  // queryKeys.costGroup('MF'),
  // queryKeys.costGroup('UF'),
  queryKeys.itemUnits,
  queryKeys.componentBaseUnits,
  queryKeys.issueTypes,
];

persistQueryClient({
  queryClient,
  persister: syncStoragePersister,
  maxAge: Infinity,
  dehydrateOptions: {
    shouldDehydrateQuery: ({ queryKey, state }) => {
      return (
        state.status !== 'pending' &&
        !!queryKeysToPersist.find((qk) => isEqual(qk, queryKey))
      );
    },
  },
});

export const persistedQueryOptions = {
  // as query is persisted, should not have staleTime: Infinity
  staleTime: 24 * 60 * 60 * 1000, // 1 day
  refetchOnWindowFocus: false,
};
